<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                
                                <el-row>
                                    <el-col :span="12" align="left">
                                        <span v-loadimage="store_address.website_logo?store_address.website_logo:''"  ></span>
                                    </el-col>
                                    <el-col :span="12" align="right">
                                            <b >
                                    <label class="mt-10">
                                      {{store_address.address?store_address.address +', ':''}}
                                      {{store_address.city_details? store_address.city_details.city_name+', ': ''}}
                                      {{store_address.state_details? store_address.state_details.state_name:''}} {{store_address.post_code ? ' - '+store_address.post_code : ''}}</label><br>
                                    <label>Phone: {{store_address.phone?store_address.phone:''}}</label><br>
                                    <label>Fax: {{store_address.fax?store_address.fax:''}}</label><br>
                                    <label>Email: {{store_address.email_address?store_address.email_address:''}}</label>
                                    </b>
                                    <br>
                                    </el-col>
                                  </el-row>
                                   <hr class="invoice-line">
                                  <el-row>
                                     
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>RECEIVE PURCHASE ORDER</b></h2>
                                      </el-col>
                                                                           
                                  </el-row>
                                   <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PURCHASE ORDER #</b></span><span><b> {{view.po_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>SUPPLIER ID : </b></span><span><b>{{view.supplier.supplier_Id}}</b></span>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name">{{view.supplier? view.supplier.company_name:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && (view.supplier.unit_no || view.supplier.street)">{{view.supplier.street ? view.supplier.street:'' }}{{view.supplier.unit_no?', Unit No : '+ view.supplier.unit_no:'' }} </div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.city">{{view.supplier.city? view.supplier.city.city_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.state">{{view.supplier.state? view.supplier.state.state_name :''}}{{view.supplier.postal_code? ' - '+view.supplier.postal_code:''}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                        <div class="billing-adr" style="padding-left:10px;" v-if="view.delivery_type == 'Delivery'">SHIP TO :</div>
                                         <div class="billing-adr" style="padding-left:10px;" v-else>PickUp :</div>
                                         <div style="padding: 3px;" v-if="view.store_details && view.store_details.website_name && view.delivery_type == 'Delivery'">{{view.store_details?view.store_details.website_name:''}}</div>
                                         <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name && view.delivery_type == 'PickUp'">{{view.supplier.company_name}}</div>
                                         <div style="padding: 3px;" v-if="view.address || view.unit_no">{{view.address}}{{view.unit_no?', Unit No : '+ view.unit_no:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.city && view.city.city_name ">{{view.city.city_name ? view.city.city_name: '' }}</div>
                                          <div style="padding: 3px;" v-if="view.state && view.state.state_name ">{{view.state.state_name}}{{view.post_code ? ' - '+view.post_code : ''}}</div>
                                      </el-col>
                                    </el-row>
                                     <div class="row">
                                      <div class="col-xl-12 ">
                                <el-table :data="form.products" stripe border fit >                                                         
                                  <el-table-column
                                    type="index"
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Supplier SKU" width="100">
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].supplier_sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>                                
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.name}} - <b>{{form.products[scope.$index].product_item.sku}}</b></span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Quantity" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Receive Quantity" width="150" align="center">
                                     <template slot-scope="scope">
                                       <el-input-number class="return_product" v-model="form.products[scope.$index].receive_quantity" auto-complete="nope"  :controls="false" ></el-input-number>                                          
                                      </template>                            
                                  </el-table-column>                          
                                </el-table>  
                                <span v-if="this.quantityError" class="red mt-5">{{this.quantityError}}</span>
                                <el-form >
                                <el-row class="mt-5">
                                  <div class="col-sm-12 mt-3">
                                      <el-form-item label="Receive Documents">
                                        <el-upload class=""
                                            action
                                            accept="image/jpeg,image/png"
                                            list-type="picture-card"
                                            :auto-upload="false"
                                            :file-list="attachments"                                                    
                                            :on-remove="removeImage"
                                            :before-remove="beforeRemove"
                                            :on-change="addAttachment"
                                            multiple>
                                            <el-button slot="trigger" size="small" type="primary" >Click Upload</el-button>
                                          </el-upload>
                                      </el-form-item>
                                      <img width="100%" :src="form.receive_document" alt="">   
                                      <span class="red">{{docError}}</span> 
                                    </div>   
                                      </el-row>
                                      <el-row>
                                  <el-col :span="14" >
                                           <el-form-item
                                            label="Other Comments or Special Instructions"
                                            prop="comments"
                                          >
                                          <el-input
                                            type="textarea"
                                            :rows="5"
                                            v-model="form.comments"
                                            @input.native="capitalize"
                                          ></el-input>
                                        </el-form-item> 
                                            </el-col>
                                </el-row>
                                  <el-row style="margin-top:25px;">
                                      <el-col :span="6" >                                             
                                        <el-form-item label="Receive PO Verified By" prop="receive_verified_by" >
                                            <el-input v-model="form.receive_verified_by" @input.native="capitalize" auto-complete="nope"  class="sub-total"  :class=" form.errors.has('receive_verified_by') ? 'error required' : 'required' "></el-input>
                                        </el-form-item>  
                                        <span class="form-err" v-if="form.errors.has('receive_verified_by')" >{{form.errors.get('receive_verified_by')}}</span> 
                                      </el-col>                                     
                                    
                                      <el-col :span="5" :offset="2" style="margin-top:20px">
                                      <el-form-item >
                                            <el-button type="primary" @click="VerifiedPO">{{submit_btn_text}}</el-button>
                                      </el-form-item>
                                      </el-col>
                                         </el-row>
                                        </el-form>     
                                      </div>
                                     </div>

                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getReceivePurchaseorder, downloadPdf} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail'
import Form from '@/core/services/api.form.services'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      store_address : [], 
      submit_btn_text:'Received',    
      view_type: 'purchaseorder',
      api_url:'receive/purchase/order',
      img_preview: "/./media/bg/profile-img.png",
        form : new Form({
        receive_verified_by:null,
        comments:null,
        receive_document:'',
        po_id:null,       
          products: [
           {
             id :null,
             product_id: null,                       
             quantity: null,            
             receive_quantity:null,
             back_quantity:null,            
             product_item: [{
               sku: null,
               name:null
             }]            
           }
         ],
        attachments:[],
        attachment:[],

       }),
       quantityError:null,
       docError:null,
       delete_image : false,
       image_btn: 0,
       attachments:[], 
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Receive PO", route: "/recive/purchase/order" }, 
      { title: "Receive Purchase Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getReceivePurchaseorder(this.$route.params.poId).then(response => { 
       
        this.view = response.data.data 
        //this.form = new Form(response.data.data);  
        this.store_address = response.data.data.store_details 
        this.form.products = response.data.data.products;     
        this.loading = false
      });
    },
    addAttachment (file) {
          let fileDocument = [];
          fileDocument.push(file);
             this.form.attachment = [];              
         fileDocument.map(async(attachment) => {
            let fileName = attachment.name;
            let blob = this.blobIsExists(fileName);           
            if(blob){
                this.form.attachments.push({image_path:fileName,image_blob:blob});               
            }else if(file != null){               
               blob = await this.readFile(attachment.raw);   
               this.form.attachments.push({image_path:fileName,image_blob:blob});                            
            }
          });
       
        },
      beforeRemove(file) {       
          return this.$confirm(`Do you really want to delete ${ file.name }？`);
      },
      removeImage (file,fileList) { 
           this.form.attachments = []; 
           fileList.map(async(attachment) => {
            let fileName = attachment.name;
            let blob = this.blobIsExists(fileName);
            if(blob){
                this.form.attachments.push({image_path:fileName,image_blob:blob});              
            }else if(file != null){   
               
                if(attachment.status === "success"){
                  this.form.attachments.push({image_path:attachment.name,image_blob:attachment.url});
               }else{
                blob = await this.readFile(attachment.raw);
                this.form.attachments.push({image_path:fileName,image_blob:blob});
               }
            }
          }); 
        
        },
         readFile(file) {
           return new Promise((resolve) => {
              
                    const fReader = new FileReader();
                    fReader.onload = () => {
                       resolve(fReader.result);
                    }
                    fReader.readAsDataURL(file);
           })
        },
        blobIsExists(filename){
            let blob = null;
            this.attachments.map((attachment) => {
              if(attachment.name === filename){
                blob = attachment.blob;
              }
            })
            return blob;
        },
     printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
        onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length <= 0) {
        return;
      }
      this.form.receive_document = files[0];
      this.img_preview = URL.createObjectURL(files[0]);
    },
    // removeImage(){
    //   this.form.brand_image = '';
    //   this.img_preview =  "/./media/bg/profile-img.png";
    // },


     VerifiedPO: function() {  
      this.loading = true;              
     this.form.po_id = this.$route.params.poId;
    
      this.quantityError = null;
      let cancelQty = 0;
        this.form.products.map((val)=>{
        if(val.receive_quantity){         
           cancelQty = cancelQty + val.receive_quantity;     
        }       
     })

     if(cancelQty <= 0){
        this.quantityError = 'Please Select Received Quantity';
        this.loading = false;
     }

     if(!this.quantityError){
        this.form.post(this.api_url).then((response) => {
            if(response.status){
                this.$router.push({ path: '/recive/purchase/order' });
                this.loading=false;
                this.$showResponse('success', response.message);
            }
          }).catch((error) => {
              this.loading = false;
                console.log(error)
              })

     }
     
    },
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.poId, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'purchaseorder.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.supplier;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    }    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;
      margin-top: 30px;
      margin-left: 10px;
    }
     .return_product .el-input__inner{    
       border: 2px solid #3699ff; 
       text-align: center !important;     
    }
    .upload-btn-container{
  display: flex !important;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 200px;
    height: 178px;
    display: block;
  }
  .preview-container img{
    max-width : 200px !important
  }

    @media print {
       
        .print-section, .download-btn, .subheader, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    
}
 
</style>